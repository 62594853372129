import {ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {TranslocoService} from "@ngneat/transloco";
import {DOCUMENT} from "@angular/common";
import {ModalState} from "../../../_stores/modal/_state/modal.state";
import {ModalCloseAction, ModalOpenAction} from "../../../_stores/modal/_actions/modal.actions";

@Component({
  selector: 'bh-modal',
  templateUrl: './bh-modal.component.html',
  styleUrls: ['./bh-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BhModalComponent implements OnInit {
  interface: {
    status: boolean,
    type: string,
    no_close: boolean,
    _cache: any
  } = {
    status: false,
    type: undefined,
    no_close: false,
    _cache: {
      subscribe: {
        modal: undefined
      }
    }
  };
  constructor(private store: Store, private cdr: ChangeDetectorRef, private transloco: TranslocoService, @Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {
    this.initModalStatus();
  }
  ngOnDestroy(): void {
    if (!this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initModalStatus(): void {
    this.interface._cache.subscribe.modal = this.store.select(ModalState.selectStatus).subscribe((res: any) => {
      if(!res) {
        return;
      }

      this.interface.status = res.status;
      this.interface.type = res.type;
      this.interface.no_close = !res.close;
      if (this.interface.status) {
        this.document.body.classList.add('bh-modal-open');
      } else {
        this.document.body.classList.remove('bh-modal-open');
      }

      this.cdr.detectChanges();
    });
  }

  actionCloseModal(e?): void {
    if (this.interface.no_close) {
      return;
    }
    if (!e || (e && e.target.className === 'bh-modal')) {
      this.store.dispatch(new ModalCloseAction());
    }
  }

  actionIssuePremium(): void {
    this.store.dispatch(new ModalOpenAction('get_premium'));
  }

}
