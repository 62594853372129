import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhGiftsComponent} from "./bh-gifts.component";
import {TranslocoModule} from "@ngneat/transloco";


@NgModule({
  declarations: [BhGiftsComponent],
  exports: [BhGiftsComponent],
  imports: [CommonModule, TranslocoModule, NgOptimizedImage]
})
export class BhGiftsModule {
}
