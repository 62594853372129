import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {
  DepartmentsCupsReportsModel,
  DepartmentsProgressReportsModel,
  DepartmentsStarsReportsModel,
  ReportsModel
} from "../_models/reports.model";
import {ReportsService} from "../_services/reports.service";
import {AuthAllowAccess, AuthDisallowAccess, AuthUpdateToken} from "../../auth/_actions/auth.actions";
import {DepartmentsState} from "../../departments/_state/departments.state";
import {
  AddDepartmentCompleteTaskReportRecord,
  AddDepartmentWinStarsReportRecord,
  AddTaskProgressReportRecord,
  AddUserBillingStarsReports,
  InitRatingReports,
  InitReports,
  InitReportsAfterDepartmentsLoad,
  UpdateDepartmentsProgressReport,
  UpdateRatingReport
} from "../_actions/reports.actions";
import {UserState} from "../../user/_state/user.state";
import {UpdatePageFromDepartment} from "../../departments/_actions/departments.actions";


export const _ReportsDefault: ReportsModel = {
  departments: {
    progress: [],
    cups: [],
    stars: [],
  },
  tasks: {
    progress: [],
  },
  user: {
    billing: {
      stars: []
    },
  },
  rating: {
    best: null,
    online: [],
    users: []
  },
  init: false
};

@State<ReportsModel>({
  name: 'BHA_REPORTS',
  defaults: _ReportsDefault,
})
@Injectable()
export class ReportsState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store, private reportsService: ReportsService) {

  }

  ngxsOnInit(ctx?: StateContext<any>): any {}
  ngxsOnChanges(change: NgxsSimpleChange<ReportsModel>): void {}

  ngxsAfterBootstrap(ctx?: StateContext<ReportsModel>): void {}

  @Selector()
  static selectDepartmentsReports(state: ReportsModel) {
    return state.departments;
  }
  @Selector()
  static selectTasksProgressReport(state: ReportsModel) {
    return state.tasks.progress;
  }
  @Selector()
  static selectCupsDepartmentReport(state: ReportsModel) {
    return state.departments.cups;
  }

  @Selector()
  static selectStarsDepartmentReport(state: ReportsModel) {
    return state.departments.stars;
  }

  @Selector()
  static selectUserBillingStarsReport(state: ReportsModel) {
    return state.user.billing.stars;
  }

  @Selector()
  static selectRatingReport(state: ReportsModel) {
    return state.rating;
  }


  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<ReportsModel>) {
    ctx.dispatch(new InitReports());
  }

  @Action(AuthUpdateToken)
  authUpdateToken(ctx: StateContext<ReportsModel>) {}

  @Action(AuthDisallowAccess)
  authDisallowAccess(ctx: StateContext<ReportsModel>) {
    ctx.patchState(_ReportsDefault);
  }


  @Action(InitReports)
  async initReports(ctx: StateContext<ReportsModel>) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);

    if(_state_user.role == 'user' || _state_user.role == 'premium') {
      let _reports = await  this.reportsService.getUserReports().toPromise();
      if(_reports) {
        ctx.patchState({..._reports, init: true});
        ctx.dispatch(new InitRatingReports());
      }
    }
    else {
      let _departments_state = this.store.selectSnapshot(DepartmentsState.selectDepartments);
      let _reports = {
        departments: {
          ..._state.departments
        },
        tasks: {
          progress: [..._state.tasks.progress],
        },
        user: {
          billing: {
            stars: [..._state.user.billing.stars]
          },
        }
      }
      ctx.patchState({..._reports, init: _departments_state.length > 0});
      ctx.dispatch(new InitRatingReports());
    }
  }

  @Action(InitRatingReports)
  async initRatingReports(ctx: StateContext<ReportsModel>) {

    let _rating_reports = await  this.reportsService.getRatingUsersReports().toPromise();
    if(_rating_reports) {
      const _state = ctx.getState();
      ctx.patchState({
        ..._state,
        rating: _rating_reports
      });
    }
  }


  @Action(InitReportsAfterDepartmentsLoad)
  async initReportsAfterDepartmentsLoad(ctx: StateContext<ReportsModel>) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);

    if(!_state.init && _state_user.role == 'guest') {
      let _departments_state = this.store.selectSnapshot(DepartmentsState.selectDepartments);
      let _reports = {
        departments: {
          progress: [..._departments_state].reduce((_report, department) => {
            return [
              ..._report,
              {
                _id: department._id,
                page: department.page,
              }
            ];
          }, <DepartmentsProgressReportsModel[]>[]),
          cups: [..._departments_state].reduce((_report, department) => {
            return [
              ..._report,
              {
                _id: department._id,
                total: department.total,
                complete: _state.tasks.progress.filter(_task => _task.complete && _task.department == department._id).length
              }
            ];
          }, <DepartmentsCupsReportsModel[]>[]),
          stars: [..._departments_state].reduce((_report, department) => {
            let _state_stars_department = _state.departments.stars.find(_r => _r._id == department._id);

            return [
              ..._report,
              {
                _id: department._id,
                count: _state_stars_department? _state_stars_department.count: 0
              }
            ];
          }, <DepartmentsStarsReportsModel[]>[]),
        },
        tasks: {
          progress: [..._state.tasks.progress],
        },
        user: {
          billing: {
            stars: [..._state.user.billing.stars]
          },
        }
      }
      ctx.patchState({..._reports, init: true});
    }
  }


  @Action(UpdateDepartmentsProgressReport)
  updateDepartmentsProgressReport(ctx: StateContext<ReportsModel>, data) {
    const _state = ctx.getState();
    let _progress_reports = _state.departments.progress.reduce((reports, report) => {
      let _report = {...report}
      if (_report._id == data.department_id) {
        _report.page = data.page;
      }
      return [...reports, _report];
    }, []);
    ctx.patchState({
      ..._state,
      departments: {
        ..._state.departments,
        progress: _progress_reports
      }
    });
  }

  @Action(UpdateRatingReport)
  async updateRatingReport(ctx: StateContext<ReportsModel>, data) {
    const _state = ctx.getState();
    let _rating_reports = null;
    if (data.department_id == 'all') {
      _rating_reports = await  this.reportsService.getRatingUsersReports().toPromise();
    } else {
      _rating_reports = await  this.reportsService.getRatingUsersReportsByDepartmentId(data.department_id).toPromise();
    }

    if(_rating_reports) {
      ctx.patchState({
        ..._state,
        rating: _rating_reports
      });
    }

  }

  @Action(AddTaskProgressReportRecord)
  addTaskProgressReportRecord(ctx: StateContext<ReportsModel>, addTaskProgressReportRecord) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    let _report = [..._state.tasks.progress].filter((_t) => _t.task_id != addTaskProgressReportRecord.record.task_id);
    _report.unshift(addTaskProgressReportRecord.record);
    ctx.patchState({
      ..._state,
      tasks: {
        ..._state.tasks,
        progress: _report
      }
    });
    if(_state_user.role == 'user' || _state_user.role == 'premium') {
      this.reportsService.addUserTaskProgressReport(addTaskProgressReportRecord.record).subscribe(() => {});
    }

    const _department_progress = _state.departments.progress.find((_d) => _d._id == _state_user.settings.department._id);
    const _department_progress_tasks = _report.filter((_t) => _t.department == _state_user.settings.department._id);

    const _department_page = _department_progress.page;
    const _department_progress_page = Math.floor(_department_progress_tasks.length/27);
    if(_department_page == _department_progress_page) {
      ctx.dispatch(new UpdateDepartmentsProgressReport(_state_user.settings.department._id, _department_page+1));
      ctx.dispatch(new UpdatePageFromDepartment(_department_page+1));
    }
  }

  @Action(AddDepartmentWinStarsReportRecord)
  addDepartmentWinStarsReportRecord(ctx: StateContext<ReportsModel>, _report_data) {
    const state = ctx.getState();
    let _reports = [...state.departments.stars].reduce((reports, report) => {
      let _report = {...report}
      if (_report._id == _report_data.record._id) {
        _report.count += _report_data.record.count;
      }
      return [...reports, _report];
    }, []);
    ctx.patchState({
      ...state,
      departments: {
        ...state.departments,
        stars: _reports
      }
    });
  }

  @Action(AddDepartmentCompleteTaskReportRecord)
  addDepartmentCompleteTaskReportRecord(ctx: StateContext<ReportsModel>, addDepartmentCompleteTaskReportRecord) {
    const state = ctx.getState();
    let _reports = [...state.departments.cups].reduce((reports, report) => {
      let _report = {...report}
      if (_report._id == addDepartmentCompleteTaskReportRecord.record._id) {
        _report.complete += 1;
      }
      return [...reports, _report];
    }, []);
    ctx.patchState({
      ...state,
      departments: {
        ...state.departments,
        cups: _reports
      }
    });
  }

  @Action(AddUserBillingStarsReports)
  addUserBillingStarsReports(ctx: StateContext<ReportsModel>, _record_data) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    let _report = [..._state.user.billing.stars];
    _report.unshift(_record_data.record);
    ctx.patchState({
      ..._state,
      user: {
        ..._state.user,
        billing: {
          ..._state.user.billing,
          stars: _report
        }
      }
    });

    if(_state_user.role == 'user' || _state_user.role == 'premium') {
      this.reportsService.addUserBillingReport(_record_data.record).subscribe(() => {});
    }
  }

}
