export class AuthAllowAccess  {
  public static readonly type = '[Auth] AuthAllowAccess';
  constructor() {}
}
export class AuthDisallowAccess  {
  public static readonly type = '[Auth] AuthDisallowAccess';
  constructor() {}
}
export class AuthNewToken  {
  public static readonly type = '[Auth] AuthNewToken';
  constructor(public token?: string) {}
}



export class AuthUpdateToken  {
  public static readonly type = '[Auth] AuthUpdateToken';
  constructor(public token?: string) {}
}
export class AuthStatus  {
  public static readonly type = '[Auth] AuthStatus';
  constructor() {}
}
export class AuthListing  {
  public static readonly type = '[Auth] AuthListing';
  constructor() {}
}
