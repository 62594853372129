import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from "@ngxs/store";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {Router} from "@angular/router";
import {ModalOpenAction} from "../../../_stores/modal/_actions/modal.actions";
import {UserModel} from "../../../_stores/user/_models/user.model";
import {UserState} from "../../../_stores/user/_state/user.state";

@Component({
  selector: 'bh-dialog',
  templateUrl: './bh-dialog.component.html',
  styleUrls: ['./bh-dialog.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'dialog', alias: 'dialog'  } }],
})
export class BhDialogComponent implements OnInit, OnDestroy {
  @Input('type') set _type(_type) {
    if (_type) {
      this.interface.type = _type;
    }
  }

  interface: {
    type: string,
    user: UserModel,
    _cache: any
  } = {
    type: null,
    user: undefined,
    _cache: {
      disabled: 2,
      disabledInterval: null,
      intro_text: '',
      subscribe: {
        transloco: undefined,
        user: undefined
      }
    }
  }


  constructor(private store: Store, private cdr: ChangeDetectorRef,  private router: Router) { }

  ngOnInit(): void {
    this.initUser();
  }
  ngOnDestroy(): void {
    if (!this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((_user: any) => {
      this.interface.user = {..._user};
    });
  }

  actionOpenAccessPage(): void {
    this.router.navigate(['/auth']).then();
  }
  actionIssuePremium(): void {
    this.store.dispatch(new ModalOpenAction('get_premium'));
  }
  actionIssuePremiumOrLogin(): void {
    if(this.interface.user.role == 'guest') {
      this.router.navigate(['/auth']).then();
    } else {
      this.store.dispatch(new ModalOpenAction('get_premium'));
    }
  }

}
