import {Injectable} from '@angular/core';
import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {ReportsModel,} from "../_models/reports.model";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  public getUserReports() {
    return this.http.get<any>(API.url('reports/user'));
  }

  public getUserBillingReports() {
    return this.http.get<any>(API.url('reports/user/billing/stars'));
  }
  public addUserBillingReport(report) {
    return this.http.post<any>(API.url('reports/user/billing/stars'), {report});
  }

  public getUserTaskProgressReports() {
    return this.http.get<any>(API.url('reports/user/task/progress'));
  }
  public addUserTaskProgressReport(report) {
    return this.http.post<any>(API.url('reports/user/task/progress'), {report});
  }

  public getUserDepartmentReports() {
    return this.http.get<any>(API.url('reports/user/departments'));
  }


  public getRatingUsersReports() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('reports/rating/users'+randomHash));
  }
  public getRatingUsersReportsByDepartmentId(_id) {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('reports/rating/users/'+_id+'/'+randomHash));
  }


  public getReportByAll() {
    return this.http.get<ReportsModel>(API.url('report'));
  }
  public getReportByTask() {
    return this.http.get<any[]>(API.url('report/tasks'));
  }
  public getReportByStars() {
    return this.http.get<any[]>(API.url('report/stars'));
  }
  public getReportByStarsDepartment() {
    return this.http.get<any[]>(API.url('report/stars_department'));
  }
}
