import {Injectable} from '@angular/core';
import {
  CanMatch,
  Route,
  UrlSegment,
  UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MatchPublicPageWorksheetGeneratorsGuard implements CanMatch {

  constructor() {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return ['reading', 'math', 'tracing', 'logic'].includes(segments[segments.length-1].path);
  }

}
