import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {TaskModel} from "../../task/_models/task.model";
import {DepartmentModel} from "../_models/departments.model";

@Injectable({
  providedIn: 'root',
})
export class DepartmentService {

  constructor(private http: HttpClient) {
  }

  public getDepartments() {
    const _url = API.url('departments');
    return this.http.get<DepartmentModel[]>(_url).toPromise();
  }

  public loadDepartmentTasks(_id: string, page?: string | number) {
    let _url = API.url('departments/'+_id+'/tasks');
    if(page) {
      _url += '/'+page;
    }
    return this.http.get<TaskModel[]>(_url);
  }

}
