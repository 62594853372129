import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BhNoDataComponent} from "./bh-no-data.component";



@NgModule({
  declarations: [BhNoDataComponent],
  exports: [BhNoDataComponent],
  imports: [
    CommonModule
  ]
})
export class BhNoDataModule { }
