<div class="bh-tip" *ngIf="interface?.show" (click)="actionCloseTips($event)">
  <div class="bh-tip-container bh-tip-{{interface.options.visual}}">
    <ng-container *ngIf="interface.options.type == 'default'"  [ngTemplateOutlet]="TIP_DEFAULT"></ng-container>
    <ng-container *ngIf="interface.options.type == 'hunter'"  [ngTemplateOutlet]="TIP_HUNTER"></ng-container>
  </div>
</div>

<ng-template #TIP_DEFAULT>
  <div class="bh-tip-header"></div>
  <div class="bh-tip-body">
    <div class="bh-tip-title">{{''+interface.options.title | transloco}}</div>
    <div class="bh-tip-text">{{''+interface.options.text | transloco}}</div>
  </div>
  <div class="bh-tip-footer">
    <div class="bh-tip-footer-charester">
      <img src="{{'./assets/images/staff/photo/'+interface.options.staff+'.svg'}}" alt="{{'staff.'+interface.options.staff+'.name' | transloco}}" />
    </div>
    <div class="bh-tip-footer-position">
      <p>{{'staff.'+interface.options.staff+'.name' | transloco}}</p>
      <span>{{'staff.'+interface.options.staff+'.position' | transloco}}</span>
    </div>
    <div class="bh-tip-footer-action">
      <button class="bh-btn bh-btn-gray" *ngIf="interface.options.buttons == 'close'" (click)="actionCloseTips()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <span>{{'buttons.close' | transloco}}</span>
      </button>
      <button class="bh-btn bh-btn-yellow" *ngIf="interface.options.buttons == 'understood'" (click)="actionCloseTips()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        <span>{{'buttons.understood' | transloco}}</span>
      </button>
      <button class="bh-btn bh-btn-yellow" *ngIf="interface.options.buttons == 'subscribe'" (click)="actionIssuePremium()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
        </svg>
        <span>{{'buttons.premium_reminder' | transloco}}</span>
      </button>
      <button class="bh-btn bh-btn-yellow" *ngIf="interface.options.buttons == 'change_card_level'" (click)="actionIssueUserCard()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
        </svg>
        <span>{{'buttons.issue_user_card' | transloco}}</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #TIP_HUNTER>
  <div class="bh-tip-header"></div>
  <div class="bh-tip-body">
    <div class="bh-tip-title">{{''+interface.options.title | transloco}}</div>
    <div class="bh-tip-text">{{''+interface.options.text | transloco}}</div>
  </div>
  <div class="bh-tip-footer">
    <div class="bh-tip-footer-charester bh-tip-hunter">
      <img src="{{'./assets/images/hunters/photo/'+interface.options.staff+'.svg'}}" alt="{{'hunters.'+interface.options.staff+'.name' | transloco}}" />
    </div>
    <div class="bh-tip-footer-position">
      <p>{{'hunters.'+interface.options.staff+'.name' | transloco}}</p>
      <span>{{'hunters.'+interface.options.staff+'.position' | transloco}}</span>
    </div>
    <div class="bh-tip-footer-action">
      <button class="bh-btn bh-btn-gray" *ngIf="interface.options.buttons == 'close'" (click)="actionCloseTips()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <span>{{'buttons.close' | transloco}}</span>
      </button>
      <button class="bh-btn bh-btn-yellow" *ngIf="interface.options.buttons == 'understood'" (click)="actionCloseTips()">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
          <path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
        </svg>
        <span>{{'buttons.understood' | transloco}}</span>
      </button>
      <button class="bh-btn bh-btn-yellow" *ngIf="interface.options.buttons == 'change_card_level'" (click)="actionIssueUserCard()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
        </svg>
        <span>{{'buttons.issue_user_card' | transloco}}</span>
      </button>
    </div>
  </div>
</ng-template>
