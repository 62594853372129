import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class BlogService {

  constructor(private http: HttpClient) {
  }

  public getPosts(page: number) {
    const _url = API.url('blog/page/'+page);
    return this.http.get<any[]>(_url).toPromise();
  }

  public getPostsBySlug(slug: string) {
    const _url = API.url('blog/'+slug+'');
    return this.http.get<any>(_url).toPromise();
  }

}
