import {environment} from "../../environments/environment";

export class API {
  static url(method: string, assets?: boolean): string {
    const _api_endpoint = environment.api_endpoint;
    //const _api_endpoint = '';
    let _request_url = `${_api_endpoint}/api/${method}`;
    if(assets) {
      _request_url = `${_api_endpoint}/${method}`;
    }
    //console.log("REQUEST URL", _request_url);
    return _request_url;
  }
}
