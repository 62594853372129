export class InitWorkbooksItems  {
  public static readonly type = '[Workbooks] InitWorkbooksItems';
  constructor() {}
}
export class InitWorkbooksLocalItems  {
  public static readonly type = '[Workbooks] InitWorkbooksLocalItems';
  constructor() {}
}
export class InitWorkbooksReviews  {
  public static readonly type = '[Workbooks] InitWorkbooksReviews';
  constructor() {}
}
