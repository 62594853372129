import {HttpClient} from "@angular/common/http";
import {API} from "../../api";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  public preorder(email: string) {
    return this.http.post<any>(API.url('auth/preorder'), {email});
  }
  public listing() {
    const randomHash = '?'+[...Array(8)].map(() => Math.random().toString(12)[2]).join('');
    return this.http.get<any>(API.url('auth/listing/'+randomHash));
  }
  public status() {
    return this.http.get<any>(API.url('auth/status'));
  }
  public invite(uin: number) {
    return this.http.get<any>(API.url('auth/invite/'+uin));
  }
  public invited(uin: string, user: any) {
    return this.http.post<any>(API.url('auth/invite/'+uin), user);
  }
  public login(login: string, password: string) {
    return this.http.post<any>(API.url('auth/login'), { login, password });
  }
  public forgot(email: string) {
    return this.http.post<any>(API.url('auth/forgot'), {email});
  }
  public forgot_confirm(password: string, token: any) {
    return this.http.post<any>(API.url('auth/forgot/confirm'), {password, token});
  }
  public create(user: any) {
    return this.http.post<any>(API.url('auth/create'), user);
  }
  public logout() {
    return this.http.post<any>(API.url('auth/logout'), {});
  }

}
