export class LoadDepartments  {
  public static readonly type = '[Departments] LoadDepartments';
  constructor() {}
}
export class InitTasksFromDepartment  {
  public static readonly type = '[Departments] InitTasksFromDepartment';
  constructor() {}
}
export class ClearTasksFromDepartment  {
  public static readonly type = '[Departments] ClearTasksFromDepartment';
  constructor() {}
}
export class UpdatePageFromDepartment  {
  public static readonly type = '[Departments] UpdatePageFromDepartment';
  constructor(public page: number) {}
}

export class LoadPrevTasksFromDepartment  {
  public static readonly type = '[Departments] LoadPrevTasksFromDepartment';
  constructor(public page: number) {}
}


export class LoadNextTasksFromDepartment  {
  public static readonly type = '[Departments] LoadNextTasksFromDepartment';
  constructor(public page: number) {}
}
