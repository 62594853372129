import {ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {Store} from "@ngxs/store";
import {DOCUMENT} from "@angular/common";
import {TipsModel} from "../../../_stores/tips/_models/tips.model";
import {TipsState} from "../../../_stores/tips/_state/tips.state";
import {TipsCloseAction} from "../../../_stores/tips/_actions/tips.actions";
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {ModalOpenAction} from "../../../_stores/modal/_actions/modal.actions";

@Component({
  selector: 'bh-tips',
  templateUrl: './bh-tips.component.html',
  styleUrls: ['./bh-tips.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'tips', alias: 'tips'  } }],
})
export class BhTipsComponent implements OnInit, OnDestroy {
  interface: TipsModel = {
    show: false,
    options: {
      type: '',
      staff:'',
      visual: '',
      title:'',
      text:'',
      buttons: ''
    },
    _cache: {
      subscribe: {
        tips: undefined
      }
    },
  }
  constructor(private store: Store, private cdr: ChangeDetectorRef, @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
    this.initTipsStatus();
  }
  ngOnDestroy(): void {
    if (!this.interface || !this.interface._cache) {return}
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initTipsStatus(): void {
    this.interface._cache.subscribe.tips = this.store.select(TipsState.selectStatus).subscribe((res: any)=> {
      this.interface = res;

      if(this.interface?.show) {
        this.document.body.classList.add("tips-show");
      } else {
        this.document.body.classList.remove("tips-show");
      }

      this.cdr.detectChanges();
    });
  }


  actionIssueUserCard(): void {
    this.store.dispatch(new TipsCloseAction());
    this.store.dispatch(new ModalOpenAction('change_multipasport_level'));
  }
  actionIssuePremium(): void {
    this.store.dispatch(new TipsCloseAction());
    this.store.dispatch(new ModalOpenAction('get_premium'));
  }


  actionCloseTips(e?): void {
    if(!e || e && e.target.className === 'bh-tip') {
      this.store.dispatch(new TipsCloseAction());
    }
  }

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.key == 'Enter' && this.interface.options.buttons == 'understood') {
      this.actionCloseTips();
    }
    if (event.key == 'Escape' && this.interface.show) {
      this.store.dispatch(new TipsCloseAction());
    }
  }

}
