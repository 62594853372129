import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Inject, Injectable, Optional, PLATFORM_ID} from "@angular/core";
import {AuthAllowAccess, AuthDisallowAccess, AuthListing, AuthStatus, AuthUpdateToken,} from "../_actions/auth.actions";
import {AuthService} from "../_services/auth.service";

import {AuthModel} from "../_models/auth.model";
import {isPlatformBrowser, isPlatformServer} from "@angular/common";
import {REQUEST} from '@nguniversal/express-engine/tokens';
import {Request} from 'express';
import {UserState} from "../../user/_state/user.state";
import {Router} from "@angular/router";
import {ChangeMaxAction, SetSoundAction} from "../../settings/_actions/settings.actions";

export const _AuthDefault: AuthModel = {
  access: false,
  status: false,
  token: null
};



@State<AuthModel>({
  name: 'BHA_AUTH',
  defaults: _AuthDefault
})

@Injectable()
export class AuthState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(
    private store: Store,
    private authService: AuthService,
    private router: Router,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Optional()
    @Inject(REQUEST) private request: Request
    ) {}

  ngxsAfterBootstrap(ctx?: StateContext<AuthModel>): void {
    if (isPlatformBrowser(this.platformId)) {
      this.store.dispatch(new AuthStatus());
    }
    if (isPlatformServer(this.platformId)) {}
  }

  ngxsOnChanges(change: NgxsSimpleChange): void {}

  ngxsOnInit(ctx: StateContext<AuthModel>): void {}


  @Selector()
  static selectAccess( state: AuthModel ) {
    return state.access;
  }

  @Selector()
  static selectAuthToken( state: AuthModel ) {
    return state.token;
  }


  @Action(AuthListing)
  async authListing(ctx: StateContext<AuthModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {
      return;
    }
    const _state_user = this.store.selectSnapshot(UserState.selectUser);
    if(_state_user.role == 'guest') {
      return;
    }
    let _listing = setInterval(() => {
      this.authService.listing().subscribe({
        next: _next => {

        },
        error: _error => {
          if(_error.status == 401) {
            clearInterval(_listing);
            ctx.dispatch(new AuthDisallowAccess());
          }
        }
      });
    }, 5000);

  }


  @Action(AuthStatus)
  authStatus(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    if(_state_user.role == 'user' || _state_user.role == 'premium') {
      this.authService.status().subscribe({
        next: _next => {
          ctx.dispatch(new AuthUpdateToken(_next.token));
          ctx.dispatch(new AuthListing());
        },
        error: _error => {
          ctx.dispatch(new AuthDisallowAccess());
        }
      });
    }
    if(_state_user.role == 'guest' && _state.access) {
      this.store.dispatch(new AuthAllowAccess());
    }
  }



  @Action(AuthAllowAccess)
  authAllowAccess(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      access: true,
    });
  }

  @Action(AuthDisallowAccess)
  authDisallowAccess(ctx: StateContext<AuthModel>) {
    const _state = ctx.getState();
    const _state_user = this.store.selectSnapshot(UserState);
    ctx.patchState({
      ..._state,
      access: false,
      token: null
    });
    ctx.dispatch(new ChangeMaxAction({energy: 10, life: 3}));
    ctx.dispatch(new SetSoundAction(true));
    if(_state_user.role == 'user' || _state_user.role == 'premium') {
      this.authService.logout().subscribe(() => {
        localStorage.removeItem('BHA_DAY');
        window.location.href = window.location.origin;
      });
    } else {
      localStorage.removeItem('BHA_DAY');
      window.location.href = window.location.origin;
    }
  }

  @Action(AuthUpdateToken)
  authUpdateToken(ctx: StateContext<AuthModel>, payload) {
    ctx.patchState({
      token: payload.token,
    });

  }

}
