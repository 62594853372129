import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhMinicartComponent} from "./bh-minicart.component";
import {
  StripeCardCvcComponent,
  StripeCardExpiryComponent,
  StripeCardGroupDirective,
  StripeCardNumberComponent
} from "ngx-stripe";
import {TranslocoModule} from "@ngneat/transloco";
import {RouterLink} from "@angular/router";
import {BhDialogModule} from "../bh-dialog/bh-dialog.module";


@NgModule({
  declarations: [BhMinicartComponent],
  exports: [BhMinicartComponent],
    imports: [
        CommonModule,
        NgOptimizedImage,
        StripeCardCvcComponent,
        StripeCardExpiryComponent,
        StripeCardGroupDirective,
        StripeCardNumberComponent,
        TranslocoModule,
        RouterLink,
        BhDialogModule
    ]
})
export class BhMinicartModule { }
