import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhGetPremiumComponent} from "./bh-get-premium.component";
import {NgxStripeModule} from "ngx-stripe";
import {TranslocoModule} from "@ngneat/transloco";
import {BhTooltipModule} from "../bh-tooltip/bh-tooltip.module";
import {BhDialogModule} from "../bh-dialog/bh-dialog.module";


@NgModule({
  declarations: [BhGetPremiumComponent],
  exports: [BhGetPremiumComponent],
    imports: [
        CommonModule,
        NgxStripeModule.forRoot('pk_live_51O7BajDbiI9E3CCDpQOUS4C9d4L2jIb1hPfwkQyh9DWo7QrTu4mwAW4V0MT4lVKD8ITA0vi4LLdVmCR3VPOnoChy00oQWNdAP3'),
        TranslocoModule,
        BhTooltipModule,
        NgOptimizedImage,
        BhDialogModule,
    ]
})
export class BhGetPremiumModule { }
