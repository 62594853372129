import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {BhDialogComponent} from "./bh-dialog.component";
import {AppTranslocoRootModule} from "../../../app.transloco.module";
import {NgxTypedJsModule} from "ngx-typed-js";


@NgModule({
  declarations: [BhDialogComponent],
  exports: [BhDialogComponent],
    imports: [
        CommonModule,
        AppTranslocoRootModule,
        NgxTypedJsModule,
        NgOptimizedImage
    ]
})
export class BhDialogModule { }
