import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {UserModel} from "../../../_stores/user/_models/user.model";
import {Store} from "@ngxs/store";
import {UserState} from "../../../_stores/user/_state/user.state";
import {ModalCloseAction} from "../../../_stores/modal/_actions/modal.actions";
import {CountUpSpendStar, UpdateUpEnergy, UpdateUpLife} from "../../../_stores/user/_actions/user.actions";

@Component({
  selector: 'bh-gifts',
  templateUrl: './bh-gifts.component.html',
  styleUrls: ['./bh-gifts.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/gifts', alias: 'components.gifts' }}]
})
export class BhGiftsComponent implements OnInit, OnDestroy, AfterViewInit {
  interface: {
    user: UserModel,
    spin: boolean,
    prize: {
      show: boolean,
      item: any,
    },
    prizes: any[],
    _cache: any
  } = {
    user: undefined,
    spin: true,
    prize: {
      show: false,
      item: undefined
    },
    prizes: [
      {
        field_min: -30,
        field_max: 30,
        type: 'stars',
        icon: 'star_group',
        count: 300
      },
      {
        field_min: 30,
        field_max: 90,
        type: 'life',
        icon: 'heart',
        count: 5
      },
      {
        field_min: 90,
        field_max: 150,
        type: 'energy',
        icon: 'lightning',
        count: 10
      },
      {
        field_min: 150,
        field_max: 210,
        type: 'stars',
        icon: 'star_group',
        count: 100
      },
      {
        field_min: 210,
        field_max: 270,
        type: 'life',
        icon: 'heart',
        count: 2
      },
      {
        field_min: 270,
        field_max: 330,
        type: 'energy',
        icon: 'lightning',
        count: 3
      }
    ],
    _cache: {
      audioplayer: null,
      gifted: false,
      subscribe: {
        user: undefined,
      }
    }
  }

  degree = 1080;
  clicks = 0;
  @ViewChild('spinBtn') spinBtn: ElementRef;
  @ViewChild('innerWheel') innerWheel: ElementRef;


  constructor(private store: Store, private cdr: ChangeDetectorRef, private renderer: Renderer2) {
    this.interface._cache.audioplayer = new Audio();

  }

  ngOnInit(): void {
    this.initUser();
    this.interface._cache.gifted = !(new Date(localStorage.getItem('BHA_DAY')).getTime() < (new Date().getTime() - 86400000));
  }
  ngAfterViewInit() {}
  ngOnDestroy(): void {
    if (!this.interface._cache) {
      return
    }
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((res: any) => {
      this.interface.user = res;
      this.cdr.detectChanges();
    });
  }

  getPrize() {
    this.interface.spin = false;
    this.playWheelSound();
    this.clicks++;
    const newDegree = this.degree * this.clicks;
    const extraDegree = Math.floor(Math.random() * (360 - 1 + 1)) + 1;
    const totalDegree = newDegree + extraDegree;


   this.interface.prize.item = this.interface.prizes.find(prize => {
     if(extraDegree > 330) {
       return  this.interface.prizes[0];
     } else {
       return extraDegree >= prize.field_min && extraDegree <= prize.field_max;
     }
   });
    const secElements = this.innerWheel.nativeElement.querySelectorAll('.sec');
    secElements.forEach((sec) => {
      let c = 0;
      const n = 440;
      const interval = setInterval(() => {
        c++;
        if (c === n) {
          if(!this.interface.prize.show) {
            this.interface.prize.show = true;
            this.playWinSound();
            this.actionWin();
          }
          clearInterval(interval);
        }
        const aoY = sec.offsetTop;
        if (aoY < 23.89) {}

      }, 10);
      this.renderer.setStyle(this.innerWheel.nativeElement, 'transform', `rotate(${totalDegree}deg)`);
    });
  }


  playWheelSound(): void {
    if(!this.interface.user.settings.sound) {return;}
    this.interface._cache.audioplayer.src = 'assets/sounds/wheel_spinner.mp3';
    this.interface._cache.audioplayer.load();
    this.interface._cache.audioplayer.play();
  }
  playWinSound(): void {
    if(!this.interface.user.settings.sound) {return;}
    this.interface._cache.audioplayer.src = 'assets/sounds/gift_win.mp3';
    this.interface._cache.audioplayer.load();
    this.interface._cache.audioplayer.play();
  }

  actionWin(): void {
    if(!this.interface.prize.item) { return;}
    if(this.interface.prize.item.type == 'stars') {
      this.store.dispatch(new CountUpSpendStar(this.interface.prize.item.count, 'pages.account.rating.reports.reasons.9'));
    }
    if(this.interface.prize.item.type == 'life') {
      this.store.dispatch(new UpdateUpLife(this.interface.prize.item.count));
    }
    if(this.interface.prize.item.type == 'energy') {
      this.store.dispatch(new UpdateUpEnergy(this.interface.prize.item.count));
    }
    localStorage.setItem('BHA_DAY', (new Date()).toDateString());
  }
  actionClose(): void {
    this.store.dispatch(new ModalCloseAction());
  }


}
