import {ExtraOptions, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {MatchPublicPageGuard} from "./_guards/public-page.guard";
import {MatchPrivatePageGuard} from "./_guards/private-page.guard";
import {MatchPublicPageWorksheetGeneratorsGuard} from "./_guards/public-page-worksheet-generators.guard";
import {MatchPublicPageWorksheetGeneratorsItemsGuard} from "./_guards/public-page-worksheet-generators-items.guard";
import {MatchPublicPageAuthActionsGuard} from "./_guards/public-page-auth-actions.guard";
import {MatchInsetDepartmentGuard} from "./_guards/isset-department.guard";
import {MatchUnsetDepartmentGuard} from "./_guards/unset-department.guard";
import {MatchPublicPageWorkbookSeriesGuard} from "./_guards/public-page-workbooks-series.guard";


const routerOptions: ExtraOptions = {
  initialNavigation: 'enabledBlocking',
  enableTracing: false,
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  scrollOffset: [0, 30]
};

const routWithComingSoon = [
  {
    path: '',
    children: [
      {
        path: 'contacts',
        loadChildren: () => import('./_ui/pages/public/bh-page-contacts/bh-page-contacts.module').then((mod) => mod.BhPageContactsModule),
      },
      {
        path: 'terms',
        loadChildren: () => import('./_ui/pages/public/bh-page-terms/bh-page-terms.module').then((mod) => mod.BhPageTermsModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./_ui/pages/public/bh-page-privacy-policy/bh-page-privacy-policy.module').then((mod) => mod.BhPagePrivacyPolicyModule),
      },
      {
        path: 'worksheet-generators',
        children: [
          {
            path: ':category/:type',
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheet-generator/bh-page-worksheet-generator.module').then((mod) => mod.BhPageWorksheetGeneratorModule),
            canMatch: [MatchPublicPageWorksheetGeneratorsItemsGuard]
          },
          {
            path: ':category',
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheet-generators-category/bh-page-worksheet-generators-category.module').then((mod) => mod.BhPageWorksheetGeneratorsCategoryModule),
            canMatch:[MatchPublicPageWorksheetGeneratorsGuard]
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-worksheet-generators/bh-page-worksheet-generators.module').then((mod) => mod.BhPageWorksheetGeneratorsModule),
          },
        ]
      },
      {
        path: 'workbooks',
        children: [
          {
            path: ':category/:workbook',
            loadChildren: () => import('./_ui/pages/public/bh-page-workbook/bh-page-workbook.module').then((mod) => mod.BhPageWorkbookModule)
          },
          {
            path: ':category',
            loadChildren: () => import('./_ui/pages/public/bh-page-workbooks-series/bh-page-workbooks-series.module').then((mod) => mod.BhPageWorkbooksSeriesModule),
            canMatch:[MatchPublicPageWorkbookSeriesGuard]
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-workbooks/bh-page-workbooks.module').then((mod) => mod.BhPageWorkbooksModule),
          },
        ]
      },
      {
        path: 'invite/:uin',
        canMatch: [MatchPublicPageGuard],
        loadChildren: () => import('./_ui/pages/public/bh-page-invite/bh-page-invite.module').then((mod) => mod.BhPageInviteModule),
        data: {
          noindex: true,
        }
      },

/*      {
        path: 'blog',
        children: [
          {
            path: 'page/:page_num',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
          },
          {
            path: ':category/page/:page_num',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
            canMatch:[MatchPublicPageBlogCategoriesGuard]
          },
          {
            path: ':category',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
            canMatch:[MatchPublicPageBlogCategoriesGuard]
          },
          {
            path: ':post',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog-post/bh-page-blog-post.module').then((mod) => mod.BhPageBlogPostModule),
            canMatch:[MatchPublicPageBlogPostGuard],
          },
          {
            path: '',
            loadChildren: () => import('./_ui/pages/public/bh-page-blog/bh-page-blog.module').then((mod) => mod.BhPageBlogModule),
          },
          {
            path: '**',
            redirectTo: '/blog'
          }
        ]
      },*/

      {
        path: 'auth',
        children: [
          {
            path: ':action/:token',
            canMatch: [MatchPublicPageGuard, MatchPublicPageAuthActionsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
            data: {
              noindex: true,
            }
          },
          {
            path: ':action',
            canMatch: [MatchPublicPageGuard, MatchPublicPageAuthActionsGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
            data: {
              noindex: true,
            }
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-auth/bh-page-auth.module').then((mod) => mod.BhPageAuthModule),
            data: {
              noindex: true,
            }
          },
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-not-found/bh-page-not-found.module').then((mod) => mod.BhPageNotFoundModule),
            data: {
              noindex: true
            }
          },
        ]
      },
      {
        path: 'account',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            children:[
              {
                path: 'logout',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-logout/bh-page-account-logout.module').then((mod) => mod.BhPageAccountLogoutModule),
              },
              {
                path: 'energy',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-energy/bh-page-account-energy.module').then((mod) => mod.BhPageAccountEnergyModule),
              },
              {
                path: 'health',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-health/bh-page-account-health.module').then((mod) => mod.BhPageAccountHealthModule),
              },
              {
                path: 'support',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-support/bh-page-account-support.module').then((mod) => mod.BhPageAccountSupportModule),
              },
              {
                path: 'finance',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-finance/bh-page-account-finance.module').then((mod) => mod.BhPageAccountFinanceModule),
              },
              {
                path: 'rating',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-rating/bh-page-account-rating.module').then((mod) => mod.BhPageAccountRatingModule),
              },
              {
                path: 'hunter',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account-hunter/bh-page-account-hunter.module').then((mod) => mod.BhPageAccountHunterModule),
              },
              {
                path: '',
                canMatch: [MatchPrivatePageGuard],
                loadChildren: () => import('./_ui/pages/private/bh-page-account/bh-page-account.module').then((mod) => mod.BhPageAccountModule),
              },
            ]
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            children:[
              {
                path: '',
                loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
                data: {
                  noindex: true,
                }
              },
              {
                path: '**',
                loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
                data: {
                  noindex: true,
                }
              }
            ]
          },
        ]
      },
      {
        path: 'rating',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-rating/bh-page-rating.module').then((mod) => mod.BhPageRatingModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'support',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-support/bh-page-support.module').then((mod) => mod.BhPageSupportModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'game',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-bonus-game/bh-page-bonus-game.module').then((mod) => mod.BhPageBonusGameModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'departments',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-departments/bh-page-departments.module').then((mod) => mod.BhPageDepartmentsModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'task/:task_id',
        children: [
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-task/bh-page-task.module').then((mod) => mod.BhPageTaskModule),
          },
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
        ]
      },
      {
        path: 'search',
        loadChildren: () => import('./_ui/pages/public/bh-page-search/bh-page-search.module').then((mod) => mod.BhPageSearchModule),
      },
      {
        path: 'ua',
        children: [
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
          },
          {
            path: '',
            canMatch: [MatchPrivatePageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-loading/bh-page-loading.module').then((mod) => mod.BhPageLoadingModule),
            data: {
              noindex: true,
              private: true
            }
          },
          {
            path: '**',
            redirectTo: '/'
          }
        ]
      },
      {
        path: '',
        children: [
          {
            path: '',
            canMatch: [MatchPublicPageGuard],
            loadChildren: () => import('./_ui/pages/public/bh-page-landing/bh-page-landing.module').then((mod) => mod.BhPageLandingModule),
          },
          {
            path: '',
            canMatch: [MatchPrivatePageGuard, MatchInsetDepartmentGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-dashboard/bh-page-dashboard.module').then((mod) => mod.BhPageDashboardModule),
          },
          {
            path: '',
            canMatch: [MatchPrivatePageGuard, MatchUnsetDepartmentGuard],
            loadChildren: () => import('./_ui/pages/private/bh-page-departments/bh-page-departments.module').then((mod) => mod.BhPageDepartmentsModule),
          }
        ]
      },
      {
        path: '**',
/*        loadChildren: () => import('./_ui/pages/public/bh-page-not-found/bh-page-not-found.module').then((mod) => mod.BhPageNotFoundModule),
        data: {
          not_found: true,
        }*/
        redirectTo: '/'
      }
    ]
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot([], routerOptions),
    RouterModule.forChild(routWithComingSoon),
  ],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule {
}
