import {Action, NgxsOnChanges, NgxsOnInit, NgxsSimpleChange, Selector, State, StateContext} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {CartModel} from "../_models/cart.model";
import {AddProductToCart, ClearProductsFromCart, DeleteProductFromCart} from "../_actions/cart.actions";
import {WorkbooksState} from "../../workbooks/_state/workbooks.state";
import {WorkbooksModel} from "../../workbooks/_models/workbooks.model";


export const _CartDefault: CartModel = {
  products: []

};

@State<CartModel>({
  name: 'BHA_CART',
  defaults: _CartDefault,
})
@Injectable()
export class CartState implements NgxsOnInit, NgxsOnChanges {

  constructor() {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  ngxsOnChanges(change: NgxsSimpleChange<CartModel>): void {}


  @Selector()
  static selectCart( state: CartModel) {
    return state;
  }
  @Selector()
  static selectCartProducts( state: CartModel) {
    return state.products;
  }
  @Selector([WorkbooksState])
  static selectCartPopulateProducts(state: CartModel, workbooks: WorkbooksModel) {
    let _cart = {...state};
    const _populate_products = _cart.products.reduce((_products, cart_product) => {
      let _cart_product = {...cart_product};
      const _workbook = workbooks.items.find(item => item._id === _cart_product.product_id);
      if (_workbook) {
        _cart_product.product = _workbook;
      }
      _products.push(_cart_product);
      return _products;
    }, []);
    _cart = {
      ..._cart,
      products: _populate_products
    };
    return {cart:_cart, cache: state};
  }


  @Action(AddProductToCart)
  public addProductToCart(ctx: StateContext<CartModel>, _product_model: any) {
    const _state = ctx.getState();
    const _products_from_cart = [..._state.products];
    const _cart_product_index = _products_from_cart.findIndex((cart_product) => cart_product.type == _product_model.product.type && cart_product.product_id == _product_model.product.product_id);
    if(_cart_product_index != -1) {
      const _count = _products_from_cart[_cart_product_index].count;
      _products_from_cart.splice(_cart_product_index, 1);
      _products_from_cart.push({..._product_model.product, count: _count+1});
    }
    else {
      _products_from_cart.push(_product_model.product);
    }
    ctx.patchState({
      ..._state,
      products: _products_from_cart
    })
  }
  @Action(DeleteProductFromCart)
  public deleteProductFromCart(ctx: StateContext<CartModel>, _product_model: any) {
    const _state = ctx.getState();
    const _state_products = [..._state.products];
    const _cart_product_index = _state.products.findIndex((cart_product) => cart_product.type == _product_model.product.type && cart_product.product_id == _product_model.product.product_id);
    _state_products.splice(_cart_product_index, 1);
    ctx.patchState({
      ..._state,
      products: _state_products
    })
  }

  @Action(ClearProductsFromCart)
  public clearProductsFromCart(ctx: StateContext<CartModel>) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      products: []
    })
  }



}
