import {API} from "../../api";
import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {ReviewModel, WorkbookModel} from "../_models/workbooks.model";

@Injectable({
  providedIn: 'root',
})
export class WorkbooksService {

  constructor(private http: HttpClient) {
  }

  public getWorkbooks() {
    const _url = API.url('workbooks');
    return this.http.get<WorkbookModel[]>(_url).toPromise();
  }

  public getWorkbookById(_id: string) {
    const _url = API.url('workbooks/'+_id+'');
    return this.http.get<WorkbookModel>(_url).toPromise();
  }

  public getWorkbookReviewsById(_id: string) {
    const _url = API.url('workbooks/'+_id+'/reviews');
    return this.http.get<ReviewModel[]>(_url).toPromise();
  }

}
