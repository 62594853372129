<div *ngIf="interface.type == 'department_tasks'" class="bh-dialog bh-dialog-teal bh-dialog-department_tasks">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.department_tasks.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.department_tasks.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/rating.svg" alt="{{'staff.rating.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.rating.name' | transloco}}</p>
      <span>{{'staff.rating.position' | transloco}}</span>
    </div>
    <div class="bh-dialog-footer-action">
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'premium_reminder'" class="bh-dialog bh-dialog-blue bh-dialog-premium_reminder">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.premium_reminder.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.premium_reminder.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/rating.svg" alt="{{'staff.rating.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.rating.name' | transloco}}</p>
      <span>{{'staff.rating.position' | transloco}}</span>
    </div>
    <div class="bh-dialog-footer-action">
      <button class="bh-btn bh-btn-yellow md:my-4" (click)="actionIssuePremium()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
        </svg>
        <span>{{'buttons.premium_reminder' | transloco}}</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'premium_reminder_blog'" class="bh-dialog bh-dialog-blue bh-dialog-premium_reminder_blog">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.premium_reminder.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.premium_reminder.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img ngSrc="./assets/images/staff/photo/rating.svg" alt="{{'staff.rating.name' | transloco}} - {{'staff.rating.position' | transloco}}" height="72" width="72"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.rating.name' | transloco}}</p>
      <span>{{'staff.rating.position' | transloco}}</span>
    </div>
    <div class="bh-dialog-footer-action">
      <button class="bh-btn bh-btn-yellow md:my-4 w-full" (click)="actionIssuePremiumOrLogin()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
        </svg>
        <span>{{'buttons.premium_reminder' | transloco}}</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'premium_reminder_page'" class="bh-dialog bh-dialog-blue bh-dialog-premium_reminder_page">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
      </svg>
      {{'dialog.premium_reminder.title' | transloco}}
    </div>
    <div class="bh-dialog-text">{{'dialog.premium_reminder.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img ngSrc="./assets/images/staff/photo/rating.svg" alt="{{'staff.rating.name' | transloco}} - {{'staff.rating.position' | transloco}}" height="72" width="72"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.rating.name' | transloco}}</p>
      <span>{{'staff.rating.position' | transloco}}</span>
    </div>
    <div class="bh-dialog-footer-action">
      <button class="bh-btn bh-btn-yellow md:my-4 w-full" (click)="actionIssuePremiumOrLogin()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z" />
        </svg>
        <span>{{'buttons.premium_reminder' | transloco}}</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'contacts'" class="bh-dialog bh-dialog-contact">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.contacts.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.contacts.text.0' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.contacts.text.1' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.contacts.text.2' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/director.svg" alt="{{'staff.director.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.director.name' | transloco}}</p>
      <span>{{'staff.director.position' | transloco}}</span>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'login'" class="bh-dialog bh-dialog-clear">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.login.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.login.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/personal.svg" alt="{{'staff.personal.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.personal.name' | transloco}}</p>
      <span>{{'staff.personal.position' | transloco}}</span>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'forgot'" class="bh-dialog bh-dialog-clear">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.forgot-password.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.forgot-password.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/personal.svg" alt="{{'staff.personal.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.personal.name' | transloco}}</p>
      <span>{{'staff.personal.position' | transloco}}</span>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'forgot-success'" class="bh-dialog bh-dialog-clear">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.forgot-password-success.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.forgot-password-success.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/personal.svg" alt="{{'staff.personal.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.personal.name' | transloco}}</p>
      <span>{{'staff.personal.position' | transloco}}</span>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'forgot-confirm'" class="bh-dialog bh-dialog-clear">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.forgot-password-confirm.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.forgot-password-confirm.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/personal.svg" alt="{{'staff.personal.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.personal.name' | transloco}}</p>
      <span>{{'staff.personal.position' | transloco}}</span>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'forgot-expire'" class="bh-dialog bh-dialog-clear">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.forgot-password-expire.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.forgot-password-expire.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/personal.svg" alt="{{'staff.personal.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.personal.name' | transloco}}</p>
      <span>{{'staff.personal.position' | transloco}}</span>
    </div>
    <div class="bh-dialog-footer-action">
      <button class="bh-btn bh-btn-yellow w-full" (click)="actionOpenAccessPage()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
        </svg>
        <span>{{'buttons.understood' | transloco}}</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'forgot-confirm-success'" class="bh-dialog bh-dialog-clear">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.forgot-password-confirm-success.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.forgot-password-confirm-success.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/personal.svg" alt="{{'staff.personal.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.personal.name' | transloco}}</p>
      <span>{{'staff.personal.position' | transloco}}</span>
    </div>
    <div class="bh-dialog-footer-action">
      <button class="bh-btn bh-btn-yellow w-full" (click)="actionOpenAccessPage()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M7.864 4.243A7.5 7.5 0 0119.5 10.5c0 2.92-.556 5.709-1.568 8.268M5.742 6.364A7.465 7.465 0 004.5 10.5a7.464 7.464 0 01-1.15 3.993m1.989 3.559A11.209 11.209 0 008.25 10.5a3.75 3.75 0 117.5 0c0 .527-.021 1.049-.064 1.565M12 10.5a14.94 14.94 0 01-3.6 9.75m6.633-4.596a18.666 18.666 0 01-2.485 5.33" />
        </svg>
        <span>{{'buttons.go_auth' | transloco}}</span>
      </button>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'forgot-confirm-error'" class="bh-dialog bh-dialog-clear bh-dialog-error">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.forgot-password-confirm-error.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.forgot-password-confirm-error.text' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/personal.svg" alt="{{'staff.personal.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.personal.name' | transloco}}</p>
      <span>{{'staff.personal.position' | transloco}}</span>
    </div>
  </div>
</div>
<div *ngIf="interface.type == 'off_premium'" class="bh-dialog bh-dialog-off_premium">
  <div class="bh-dialog-header"></div>
  <div class="bh-dialog-body" >
    <div class="bh-dialog-title">{{'dialog.off_premium.title' | transloco}}</div>
    <div class="bh-dialog-text">{{'dialog.off_premium.text.0' | transloco}}</div>
  </div>
  <div class="bh-dialog-footer">
    <div class="bh-dialog-footer-charester">
      <img src="./assets/images/staff/photo/director.svg" alt="{{'staff.director.name' | transloco}}"/>
    </div>
    <div class="bh-dialog-footer-position">
      <p>{{'staff.director.name' | transloco}}</p>
      <span>{{'staff.director.position' | transloco}}</span>
    </div>
  </div>
</div>
