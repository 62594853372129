import {
  Action,
  NgxsAfterBootstrap,
  NgxsOnChanges,
  NgxsOnInit,
  NgxsSimpleChange,
  Selector,
  State,
  StateContext,
  Store
} from "@ngxs/store";
import {Injectable} from "@angular/core";
import {UserModel} from "../_models/user.model";

import {
  ChangeLanguageAction,
  ChangeMaxAction,
  ChangeSoundAction,
  SetSoundAction,
} from "../../settings/_actions/settings.actions";

import {UserService} from "../_services/user.service";
import {DepartmentModel} from "../../departments/_models/departments.model";
import {DepartmentsState} from "../../departments/_state/departments.state";
import {Router} from "@angular/router";
import {
  BuyItemToPersonalHunter,
  ChangeUserDepartmentAction,
  CountDownEnergy,
  CountDownLife,
  CountDownSpendStar,
  CountUpEnergy,
  CountUpLife,
  CountUpSpendStar,
  initUser,
  UpdatePersonalHunter,
  UpdateUpEnergy,
  UpdateUpLife,
  updateUser
} from "../_actions/user.actions";
import {TranslocoService} from "@ngneat/transloco";
import {
  AuthAllowAccess,
  AuthDisallowAccess,
  AuthListing,
  AuthNewToken,
  AuthUpdateToken
} from "../../auth/_actions/auth.actions";
import {StartTimer, StopTimer} from "../../timer/_actions/timer.actions";
import {SettingsState} from "../../settings/_state/settings.state";
import {TipsOpenAction} from "../../tips/_actions/tips.actions";
import {AuthState} from "../../auth/_state/auth.state";
import {AddUserBillingStarsReports} from "../../reports/_actions/reports.actions";

export const _UserDefault: UserModel = {
  _id: null,
  email: null,
  first_name: "",
  last_name: "",
  display_name: "",
  avatar: "",
  role: "guest",
  friends: [],
  bin: null,
  score: {
    energy: 10,
    life: 3,
    star: {
      all: 0,
      spend: 0
    }
    },
  settings: {
    sound: true,
    style: 'light',
    language: 'en',
    department: null,
    speed: {
      energy: 1,
      life: 1
    }
  },
  billing: {
    first_name: "",
    last_name: "",
    country: "",
    city: "",
    state: "",
    address: "",
    postcode: null,
    phone: null

  },
  hunter: {
    selecting: {
      eyes: 0,
      body: 0,
      headdress: 0,
      leather: 0,
      mouth: 0
    },
    items: {
      eyes: [0],
      body: [0],
      headdress: [0],
      leather: [0],
      mouth: [0]
    }
  }
};

@State<UserModel>({
  name: 'BHA_USER',
  defaults: _UserDefault,
})
@Injectable()
export class UserState implements NgxsOnInit, NgxsOnChanges, NgxsAfterBootstrap {

  constructor(private store: Store, private userService: UserService, private router: Router, private transloco: TranslocoService) {}

  ngxsOnInit(ctx?: StateContext<any>): any {}

  ngxsOnChanges(change: NgxsSimpleChange<UserModel>): void {}

  ngxsAfterBootstrap(ctx: StateContext<UserModel>) {}



  @Selector()
  static selectSettings(state: UserModel) {
    return state.settings;
  }

  @Selector()
  static selectUser(state: UserModel) {
    return state;
  }
  @Selector()
  static selectDepartment(state: UserModel) {
    return state.settings.department;
  }


  @Selector()
  static selectUserEnergy(state: UserModel){
    return state.score.energy;
  }

  @Selector([DepartmentsState])
  static selectUserPopulateDepartment(state: UserModel, department: DepartmentModel[]) {
    let _user = {...state};
    let _dep_id = (state.settings.department?._id)? state.settings.department?._id : state.settings.department;
    _user = {
      ..._user,
      settings: {
        ..._user.settings,
        department: department.find((_deps) => _deps._id == _dep_id)
      }
    };
    return _user;
  }

  @Action(AuthDisallowAccess)
  authDisallowAccess(ctx: StateContext<UserModel>) {
    const _state = ctx.getState();
    let _user = {
      ..._UserDefault,
      settings: {
        ..._UserDefault.settings,
        sound: _state.settings.sound,
        language: _state.settings.language
      }
    };
    ctx.setState(_user);
  }

  @Action(AuthNewToken)
  async authNewToken(ctx: StateContext<UserModel>) {
    const _user = await this.userService.get().toPromise();
    if(_user) {
      ctx.setState(_user);
      if(_user.role == 'premium') {
        ctx.dispatch(new ChangeMaxAction({energy: 20, life: 6}));
      } else {
        ctx.dispatch(new ChangeMaxAction({energy: 10, life: 3}));
      }
      ctx.dispatch(new AuthAllowAccess());
      ctx.dispatch(new AuthListing());
      this.transloco.setActiveLang(_user.settings.language);
      ctx.dispatch(new ChangeLanguageAction(_user.settings.language));
      ctx.dispatch(new SetSoundAction(_user.settings.sound));
      this.router.navigate(['/']).then();
    }
  }


  @Action(AuthUpdateToken)
  async authUpdateToken(ctx: StateContext<UserModel>) {
    const _user = await this.userService.get().toPromise();
    if(_user) {
      if(_user.role == 'premium') {
        ctx.dispatch(new ChangeMaxAction({energy: 20, life: 6}));
      } else {
        ctx.dispatch(new ChangeMaxAction({energy: 10, life: 3}));
      }
      ctx.setState(_user);
      ctx.dispatch(new AuthAllowAccess());
      this.transloco.setActiveLang(_user.settings.language);
      ctx.dispatch(new ChangeLanguageAction(_user.settings.language));
      ctx.dispatch(new SetSoundAction(_user.settings.sound));
    }
  }




  @Action(ChangeSoundAction)
  public changeSoundAction(ctx: StateContext<UserModel>) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      settings: {
        ...state.settings,
        sound: !state.settings.sound
      }
    });
    if (state.role == 'user' || state.role == 'premium'){
      this.userService.updateSound(!state.settings.sound).subscribe();
    }
  }
  @Action(ChangeLanguageAction)
  public changeLanguageAction(ctx: StateContext<UserModel>, payload) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      settings: {
        ...state.settings,
        language: payload.language,
      }
    });
    if (state.role == 'user' || state.role == 'premium'){
      this.userService.updateLanguage(payload.language).subscribe();
    }
  }
  @Action(ChangeUserDepartmentAction)
  public changeDepartmentAction(ctx: StateContext<UserModel>, _department) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      settings: {
        ...state.settings,
        department: _department.data
      }
    });
    if (state.role == 'user' || state.role == 'premium'){
      this.userService.updateDepartment(_department.data._id).subscribe();
    }
  }





  @Action(initUser)
  public initUser(ctx: StateContext<UserModel>, _userData) {
    const _state = ctx.getState();
    const _user = _userData.user;
    ctx.patchState({
      ..._state,
      first_name: _user.first_name,
      last_name: _user.last_name,
      display_name: _user.first_name + ' ' + _user.last_name,
      avatar: _user.avatar,
      email: _user.email,
      billing: {
        ..._state.billing,
        first_name: _user.first_name,
        last_name: _user.last_name,
      }
    });
  }
  @Action(updateUser)
  public updateUser(ctx: StateContext<UserModel>, _userData) {
    const _state = ctx.getState();
    const _user = _userData.user;
    const _user_state = {..._userData.user};
    if(_user_state.password) {
      delete _user_state.password;
    }

    if(_state.settings.language != _user.settings.language) {
      this.transloco.setActiveLang(_user.settings.language);
      ctx.dispatch(new ChangeLanguageAction(_user.settings.language));
    }

    ctx.setState(_user_state);
    if (_state.role == 'user' || _state.role == 'premium'){
      let _update_user_fields = ['avatar', 'billing', 'display_name', 'email', 'first_name', 'last_name', 'password'];
      const updated_user_data = (_user, _update_user_fields) =>
        _update_user_fields.reduce((acc, key) => {
          if (_user.hasOwnProperty(key)) {
            acc[key] = _user[key];
          }
          return acc;
        }, {});
      const updatedUserData = updated_user_data(_user, _update_user_fields);
      this.userService.updateAccountData(updatedUserData).subscribe();
    }
  }



  @Action(CountDownEnergy)
  public countDownEnergy(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const _state = ctx.getState();
    const _energy = (_state.score.energy > 0) ? _state.score.energy - 1 : 0;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        energy: _energy,
      }
    });
    ctx.dispatch(new StartTimer('energy'));

    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateEnergy(-1).subscribe();
    }


  }
  @Action(CountUpEnergy)
  public countUpEnergy(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.energy == state_settings.max.energy) {return;}

    if (_state.score.energy + 1  == state_settings.max.energy) {
      ctx.dispatch(new TipsOpenAction({
        type: 'default',
        staff: 'energy',
        visual: 'energy',
        title: 'tips.energy.full.title',
        text: 'tips.energy.full.text',
        buttons: 'understood',
      }));
      ctx.dispatch(new StopTimer('energy'));
    }

    const _energy = _state.score.energy + 1;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        energy:  _energy,
      }
    });
    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateEnergy(1).subscribe();
    }
  }
  @Action(UpdateUpEnergy)
  public updateUpEnergy(ctx: StateContext<UserModel>, _energyData) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.energy + 1  == state_settings.max.energy) {
      ctx.dispatch(new TipsOpenAction({
        type: 'default',
        staff: 'energy',
        visual: 'energy',
        title: 'tips.energy.full.title',
        text: 'tips.energy.full.text',
        buttons: 'understood',
      }));
    }

    let _energy = _state.score.energy + _energyData.count;

    if(_energyData.from_timer && _energy > state_settings.max.energy) {
      _energy = state_settings.max.energy;
    }

    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        energy:  _energy,
      }
    });
    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateEnergy(_energyData.count).subscribe();
    }
  }



  @Action(CountDownLife)
  public countDownLife(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const _state = ctx.getState();

    const _life = (_state.score.life > 0) ? _state.score.life - 1 : 0;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        life: _life,
      }
    });


    ctx.dispatch(new StartTimer('life'));

    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateLife(-1).subscribe();
    }

  }
  @Action(CountUpLife)
  public countUpLife(ctx: StateContext<UserModel>) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.life == state_settings.max.life) {return;}

    if (_state.score.life + 1  == state_settings.max.life) {
      ctx.dispatch(new TipsOpenAction({
        type: 'default',
        staff: 'health',
        visual: 'health',
        title: 'tips.health.full.title',
        text: 'tips.health.full.text',
        buttons: 'understood',
      }));
      ctx.dispatch(new StopTimer('life'));
    }

    const _life = _state.score.life + 1;
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        life:  _life,
      }
    });
    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateLife(1).subscribe();
    }
  }
  @Action(UpdateUpLife)
  public updateUpLife(ctx: StateContext<UserModel>, _lifeData) {
    if(!this.store.selectSnapshot(AuthState.selectAccess)) {return;}

    const state_settings = this.store.selectSnapshot(SettingsState);
    const _state = ctx.getState();

    if (_state.score.life + 1  == state_settings.max.life) {
      ctx.dispatch(new TipsOpenAction({
        type: 'default',
        staff: 'health',
        visual: 'health',
        title: 'tips.health.full.title',
        text: 'tips.health.full.text',
        buttons: 'understood',
      }));
    }

    let _life = _state.score.life + _lifeData.count;

    if(_lifeData.from_timer && _life > state_settings.max.life) {
      _life = state_settings.max.life;
    }

    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        life:  _life,
      }
    });

    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateLife(_lifeData.count).subscribe();
    }

  }



  @Action(CountDownSpendStar)
  public countDownSpendStar(ctx: StateContext<UserModel>, CountDownSpendStar: CountDownSpendStar) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        star: {
          ..._state.score.star,
          spend: _state.score.star.spend - CountDownSpendStar.count
        },
      }
    });
    ctx.dispatch(new AddUserBillingStarsReports({
      type: 'minus',
      count: CountDownSpendStar.count,
      reason: CountDownSpendStar.reason
    }));

    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateSpendStar(CountDownSpendStar.count*-1).subscribe();
    }
  }

  @Action(CountUpSpendStar)
  public countUpSpendStar(ctx: StateContext<UserModel>, CountUpSpendStar: CountUpSpendStar) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      score: {
        ..._state.score,
        star: {

          ..._state.score.star,
          spend: _state.score.star.spend + CountUpSpendStar.count,
          all: _state.score.star.all + CountUpSpendStar.count
        },
      }
    });
    ctx.dispatch(new AddUserBillingStarsReports({
      type: 'plus',
      count: CountUpSpendStar.count,
      reason: CountUpSpendStar.reason
    }));


    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updateSpendStar(CountUpSpendStar.count).subscribe();
    }

  }



  @Action(BuyItemToPersonalHunter)
  public buyItemToPersonalHunter(ctx: StateContext<UserModel>, BuyItemToPersonalHunter: BuyItemToPersonalHunter) {
    const _state = ctx.getState();
    const _personal_hunter_items = { ..._state.hunter.items };
    const _hunter_type = BuyItemToPersonalHunter.type;
    const hunter_type_array = [..._personal_hunter_items[_hunter_type]];
    hunter_type_array.push(BuyItemToPersonalHunter.item);
    _personal_hunter_items[_hunter_type] = [...hunter_type_array];
    ctx.patchState({
      ..._state,
      hunter: {
        ..._state.hunter,
        items: _personal_hunter_items
      }
    });



    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updatePersonalHunterItems(_personal_hunter_items).subscribe();
    }

  }

  @Action(UpdatePersonalHunter)
  public updatePersonalHunter(ctx: StateContext<UserModel>, UpdatePersonalHunter: UpdatePersonalHunter) {
    const _state = ctx.getState();
    ctx.patchState({
      ..._state,
      hunter: {
        ..._state.hunter,
        selecting: UpdatePersonalHunter.hunter
      }
    });



    if (_state.role == 'user' || _state.role == 'premium'){
      this.userService.updatePersonalHunter(UpdatePersonalHunter.hunter).subscribe();
    }

  }

}
