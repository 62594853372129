<div class="bh-gift">
  <ng-container *ngIf="interface._cache.gifted">
    <div class="bh-gift-prize-gifted">
      <img src="assets/images/backgrounds/bg_day_gifted.svg" alt="gifted">
      <p>{{'components.gifts.gift_earned' | transloco}}</p>
    </div>
  </ng-container>
  <ng-container *ngIf="!interface._cache.gifted">
    <div *ngIf="interface.prize.show" class="bh-gift-prize">
      <div class="bh-gift-prize-title">{{'components.gifts.you_prize_won' | transloco}}</div>
      <div class="bh-gift-prize-data">
        <img src="assets/images/icons/{{interface.prize.item.icon}}.svg" alt="stars">
        <p>{{interface.prize.item.count}}</p>
      </div>
    </div>
    <div *ngIf="interface.prize.show" class="bh-gift-prize-text">{{'components.gifts.gift_text' | transloco}}</div>
    <div *ngIf="!interface.prize.show" class="bh-gift-wheel">
      <div class="bh-gift-wheel-spinner" #innerWheel style="transform: rotate(0deg);">
        <span class="sec"><p>{{interface.prizes[5].count}}</p></span>
        <span class="sec"><p>{{interface.prizes[4].count}}</p></span>
        <span class="sec"><p>{{interface.prizes[3].count}}</p></span>
        <span class="sec"><p>{{interface.prizes[2].count}}</p></span>
        <span class="sec"><p>{{interface.prizes[1].count}}</p></span>
        <span class="sec"><p>{{interface.prizes[0].count}}</p></span>
      </div>
      <div class="bh-gift-wheel-text">
        <span>{{'components.gifts.you_prize' | transloco}}</span>
        <div id="spin" #spinBtn>
          <div id="inner-spin"></div>
        </div>
      </div>
    </div>
    <div class="bh-gift-action">
      <button *ngIf="!interface.prize.show" class="bh-btn bh-btn-yellow" (click)="getPrize()" [disabled]="!interface.spin">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
        </svg>
        <span>{{'buttons.get_gift' | transloco}}</span>
      </button>
      <button *ngIf="interface.prize.show" class="bh-btn bh-btn-yellow" (click)="actionClose()">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
        </svg>
        <span>{{'buttons.understood' | transloco}}</span>
      </button>
    </div>
  </ng-container>
</div>
