import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TRANSLOCO_SCOPE} from "@ngneat/transloco";
import {StripeCardNumberComponent, StripeService} from "ngx-stripe";
import {UserModel} from "../../../_stores/user/_models/user.model";
import {StripeCardElementOptions, StripeElementsOptions} from "@stripe/stripe-js";
import {Store} from "@ngxs/store";
import {UserService} from "../../../_stores/user/_services/user.service";
import {UserState} from "../../../_stores/user/_state/user.state";
import {StripeElementLocale} from "@stripe/stripe-js/types/stripe-js/elements-group";
import {ModalCloseAction} from "../../../_stores/modal/_actions/modal.actions";
import {CartState} from "../../../_stores/cart/_state/cart.state";
import {CartModel} from "../../../_stores/cart/_models/cart.model";
import {Router} from "@angular/router";
import {ClearProductsFromCart, DeleteProductFromCart} from "../../../_stores/cart/_actions/cart.actions";

@Component({
  selector: 'bh-minicart',
  templateUrl: './bh-minicart.component.html',
  styleUrls: ['./bh-minicart.component.scss'],
  providers: [{ provide: TRANSLOCO_SCOPE, useValue: { scope: 'components/minicart', alias: 'components.minicart' }}]
})
export class BhMinicartComponent  implements OnInit, OnDestroy {
  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;
  interface: {
    submit: boolean,
    user: UserModel,
    cart: CartModel,
    payment_active: boolean,
    payment: {
      stripe: {
        customer_id: string,
        elementsOptions: StripeElementsOptions,
        cardOptions: StripeCardElementOptions
      }
    },
    _cache: any
  } = {
    submit: false,
    user: undefined,
    cart: undefined,
    payment_active: true,
    payment: {
      stripe: {
        customer_id: undefined,
        elementsOptions: {
          locale: 'en'
        },
        cardOptions: {
          style: {
            base: {
              fontFamily: 'Nunito, sans-serif',
              '::placeholder': {
                "fontSize": '0.75rem',
                "lineHeight": '1rem',
                "fontWeight": 600,
                color: '#9ca3af',
              },
            },
          }
        }
      }
    },
    _cache: {
      cart: undefined,
      payment: false,
      payment_complete: false,
      payment_not_complete: false,
      subscribe: {
        user: undefined,
        cart: undefined,
      },
      error_fields: [],
      error_messages: {
        number: '',
        expire: '',
        cvc: '',
      },
      card: {
        number: false,
        expire: false,
        cvc: false,
      }
    }
  }

  constructor(private store: Store, private cdr: ChangeDetectorRef, private userService: UserService, private stripeService: StripeService, private router: Router) {
  }

  ngOnInit(): void {
    this.initUser();
    this.initCart();
  }

  ngOnDestroy(): void {
    if (!this.interface._cache) {
      return
    }
    Object.keys(this.interface._cache.subscribe).forEach((_subscribe) => {
      if (this.interface._cache.subscribe[_subscribe]) {
        this.interface._cache.subscribe[_subscribe].unsubscribe();
      }
    });
  }

  initUser(): void {
    this.interface._cache.subscribe.user = this.store.select(UserState.selectUser).subscribe((_user: any) => {
      this.interface.user = {..._user};
      this.interface.payment.stripe.elementsOptions.locale = this.interface.user.settings.language as StripeElementLocale;
      if(this.interface.user.role != 'guest') {
        this.initUserStripeCustomer();
      }
      this.cdr.detectChanges();
    });
  }
  initCart(): void {
    this.interface._cache.subscribe.cart = this.store.select(CartState.selectCartPopulateProducts).subscribe((_cart) => {
      this.interface.cart = _cart.cart;
      this.interface._cache.cart = _cart.cache;
      if(this.interface.cart.products.length == 0 && !this.interface._cache.payment_complete && !this.interface._cache.payment_not_complete) {
        this.store.dispatch(new ModalCloseAction());
      }
      this.initTotalCost();
      this.initTotalProducts();
      this.cdr.markForCheck();
    });
  }

  initUserStripeCustomer(): void {
    this.userService.getStripeCustomer().subscribe(_customer => {
      this.interface.payment.stripe.customer_id = _customer.customer_id;
    });
  }

  initTotalCost(): void {
    let totalCost = 0;
    for (const product of this.interface.cart.products) {
      totalCost += product.count * product.cost;
    }
    this.interface.cart = {...this.interface.cart, total: totalCost};
    this.interface._cache.cart = {...this.interface._cache.cart, total: totalCost};
  }
  initTotalProducts(): void {
    let totalProducts = 0;
    for (const product of this.interface.cart.products) {
      totalProducts += product.count;
    }
    this.interface.cart = {...this.interface.cart, count: totalProducts};
    this.interface._cache.cart = {...this.interface._cache.cart, count: totalProducts};
  }

  actionEnterBillingPhone(event): void {
    this.interface.user.billing = {
      ...this.interface.user.billing,
      phone: event.target.value
    };
    this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'billing_phone');
  }
  actionGrantBillingPhone(check?): boolean {
    let _grant = true;
    if (!this.interface.user.billing.phone || this.interface.user.billing.phone?.toString().length < 12) {
      _grant = false;
    }
    if(!_grant) {
      if(!check) {
        this.interface._cache.error_fields.push('billing_phone');
      }
    }

    return _grant;
  }
  actionCardNumberChange(event): void {
    this.interface._cache.card.number = event.complete;
    if (event.error) {
      this.interface._cache.error_fields.push('number');
      this.interface._cache.error_messages.number = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'number');
      this.interface._cache.error_messages.number = '';
    }
  }
  actionCardExpireChange(event): void {
    this.interface._cache.card.expire = event.complete;
    if (event.error) {
      this.interface._cache.error_fields.push('expire');
      this.interface._cache.error_messages.expire = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'expire');
      this.interface._cache.error_messages.expire = '';
    }
  }
  actionCardCvcChange(event): void {
    this.interface._cache.card.cvc = event.complete;
    if (event.error) {
      this.interface._cache.error_fields.push('cvc');
      this.interface._cache.error_messages.cvc = event.error.message;
    } else {
      this.interface._cache.error_fields = this.interface._cache.error_fields.filter((e) => e !== 'cvc');
      this.interface._cache.error_messages.cvc = '';
    }
  }
  checkPaymentFields(): boolean {
    const _stripe_fields = Object.values(this.interface._cache.card).every(field => field === true);
    const _form_fields = this.actionGrantBillingPhone(true);
    return _form_fields && _stripe_fields;
  }

  actionViewCart(): void {
    this.interface._cache.payment = false;
  }
  actionDeleteFromCart(cart_item): void {
    this.store.dispatch(new DeleteProductFromCart(cart_item));
  }
  actionOpenWorkbook(workbook): void {
    this.router.navigate(['/workbooks/'+workbook.category+'/'+workbook.slug]).then();

  }
  actionGoPayment(): void {
    this.interface._cache.payment = true;
  }
  actionConfirmPayment(): void {
    this.interface.submit = true;
    this.stripeService.createPaymentMethod({
      type: 'card',
      card: this.card.element,
      billing_details: {
        phone: this.interface.user.billing.phone.toString(),
        email: this.interface.user.email
      }
    }).subscribe((result) => {
      if (result.paymentMethod) {
        const stripe_user_data = {
          payment_method_id: result.paymentMethod.id,
          phone: this.interface.user.billing.phone,
          email: this.interface.user.email,
          customer_id: this.interface.payment.stripe.customer_id,
          cart: this.interface._cache.cart
        }
        this.userService.checkoutStripe(stripe_user_data).subscribe(_checkout => {
          this.interface.submit = false;
          this.interface._cache.payment = false;
          if(_checkout.status == 'succeeded') {
            this.interface._cache.payment_complete = true;
            this.store.dispatch(new ClearProductsFromCart());
          }
          if(_checkout.status != 'succeeded') {
            this.interface._cache.payment_not_complete = true;
            this.store.dispatch(new ClearProductsFromCart());
          }


        });
      }
      if (result.error) {
        this.interface.submit = false;
      }
    });
  }
  actionClose(): void {
    this.store.dispatch(new ModalCloseAction());
  }

}
