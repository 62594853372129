import {
  DepartmentsCupsReportsModel,
  DepartmentsStarsReportsModel,
  TaskProgressReportsModel,
  UserBillingStarsReportsModel
} from "../_models/reports.model";

export class InitReports  {
  public static readonly type = '[Reports] InitReports';
  constructor() {}
}
export class InitReportsAfterDepartmentsLoad  {
  public static readonly type = '[Reports] InitReportsAfterDepartmentsLoad';
  constructor() {}
}
export class InitRatingReports  {
  public static readonly type = '[Reports] InitRatingReports';
  constructor() {}
}

export class UpdateDepartmentsProgressReport  {
  public static readonly type = '[Reports] UpdateDepartmentsProgressReport';
  constructor(public department_id: string, public page: number) {}
}

export class AddTaskProgressReportRecord  {
  public static readonly type = '[Reports] AddTaskProgressReportRecord';
  constructor(public record: TaskProgressReportsModel) {}
}

export class AddDepartmentWinStarsReportRecord  {
  public static readonly type = '[Reports] AddDepartmentWinStarsReportRecord';
  constructor(public record: DepartmentsStarsReportsModel) {}
}

export class AddDepartmentCompleteTaskReportRecord  {
  public static readonly type = '[Reports] AddDepartmentCompleteTaskReportRecord';
  constructor(public record: DepartmentsCupsReportsModel) {}
}

export class AddUserBillingStarsReports  {
  public static readonly type = '[Reports] AddUserBillingStarsReports';
  constructor(public record: UserBillingStarsReportsModel) {}
}


export class UpdateRatingReport  {
  public static readonly type = '[Reports] UpdateRatingReport';
  constructor(public department_id: string) {}
}
